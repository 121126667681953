// import Menu from './components/menu';
// import ToggleMenu from './components/toggle-menu-turbolinked';
import Expandable from './components/expandable';
import Search from './components/search';
import IframeExample from './components/iframe-example';
import CopyCode from './components/copy-code';
import CopyColor from './components/copy-color';
import { View } from './view';

// import '../scss/ds-styles.scss';

console.log('BODY SCRIPT LOADED')

function ready(fn) {
	if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
		// console.log('old')
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}
// test
ready(() => {

	hljs.configure({
		languages: ['html', 'xml', 'js']
	})

	document.querySelectorAll('pre code').forEach((block) => {
		hljs.highlightBlock(block);
	});
	
	document.querySelectorAll('code.hljs,code.nohighlight').forEach((block) => {
		hljs.lineNumbersBlock(block, {
			singleLine: true
		});
	})
	
	new Search(document.querySelector('.js--search'));
	
	View.viewFactory('.js--expandable', Expandable);
	View.viewFactory('.js--copy-code', CopyCode);
	View.viewFactory('.js--copy-color', CopyColor);

	
	// View.viewFactory('.js--toggle-menu', ToggleMenu);
	// new Menu(document.querySelectorAll('[data-sh-menu]')[0]);

	View.viewFactory('.js--example-iframe', IframeExample);
})
