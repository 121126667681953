import Emitter from 'es6-event-emitter';

class Events extends Emitter {
    constructor () {
        super();
        this._state = {};
        this.stateIgnited = false;
    }
    /**
     * 
     * @param {Obj} stateObject to use as initial global State
     */
    initState (state) {
        this._state = state || {};
        this.stateIgnited = true;
    }
    
    /**
     * 
     * @param {Obj/Func} state object to set as state if a func is provided it will be invoked with the current state obj.  
     */
    setState (state) {
        const target = this._state;
        const source = typeof state === 'function' ? state.call(this, target) : state;
		for (const key in source) target[key] = source[key];
		this.trigger('store:update', this._state);
	}
	/**
     * 
     * @param {Func} subscribtion callback 
     */
	subscribe (cb) {
		this.on('store:update', cb);
	}
	
	getState () {
		return this._state;
    }
    get state () {
        return this._state;
    }
}
const EventEmitter = new Events();
/**
 * @param el{HTMLElement}
 * @param delegateEvents{bool} 
 */
class View {
    constructor (el=null, options={}) {
        this.el = el || document.createElement('span');
		this.mutationObservers = [];
		
		this.delegateDomMutationObservers();
		this.state = this.initialState; // get initial state from child class
		this.options = {
			delegateEvents: true
		};
		Object.assign(this.options, this.initialOptions, options);
		if (this.options.delegateEvents) {
            this.delegateEvents();
        }
		
    }
	/**
     * placeholder
	 */
	delegateEvents() {
        return;
	}
	/**
     * placeholder 
	 */
	unDelegateEvents() {
		return;
	}
    
     /**
      * Attaches an event listener
	 * @param {String} eventName - the event string
     * @param {String} selector - the associated DOMelement
     * @param {Function} eventHandler - the handler function
     */
    on(eventName=null, selector=null, eventHandler=null) {
		const triggers = typeof selector === 'string' ? this.el.querySelectorAll(selector) : [selector];
		[...triggers].forEach(el=>{
            if (el.addEventListener) {
                el.addEventListener(eventName, eventHandler.bind(this), false);
			} else {
                el.attachEvent('on' + eventName, function(){
                    eventHandler.call(el);
				});
			}
		});
	}
	/**
     * Removes an event listener
     * @param {String} eventName - the event string
     * @param {String} selector - the associated DOMelement
     * @param {Function} eventHandler - the handler function
	 */
    off(eventName=null, selector=null, eventHandler=null) {
        const triggers = typeof selector === 'string' ? this.el.querySelectorAll(selector) : [selector];
		[...triggers].forEach(el=>{
            if (el.removeEventListener) {
                el.removeEventListener(eventName, eventHandler, false);
			} else {
                el.detachEvent('on' + eventName, eventHandler);
			}
		});
	}
    
    find(selector, context) {
        return context ? context.querySelectorAll(selector) : this.el.querySelectorAll(selector);
    }
    
    delegateDomMutationObservers () {
		[].forEach.call(this.find('[data-mutation-observer]'), this.delegateMutationObserver.bind(this));
	}
    
	delegateMutationObserver (element) {
		if(!element || element.classList.contains('dom-control-init')) return;
		const _mo = new MutationObserver(this._onMutation.bind(this));
		_mo.observe(element, { attributes: true, childList: true, characterData: true });
		this.mutationObservers.push(_mo);
		return _mo;
	} 
    
	undelegateMutationOberservers () {
		this.mutationObservers.forEach(mO=>{
            mO.disconnect();
		});
		this.mutationObservers = [];
		return true;
    }
    
    /**
     * onMutation - 
     * @param {nodeList} mutation 
	 */
    _onMutation (mutation) {
        this.undelegateEvents();
		this.delegateEvents();
		[].forEach.call(mutation, mu=>{
            Events.triggerEvent(mu.target, 'mutationObserver:done', mu);
		});
	}
    
	 /**
      * Super simple local state management
      * @param {Obj|Func} state Obj or function to be calledback to receive a new stateObject
      */
     setState(state) {
        const target = this.state;
        const source = typeof state === 'function' ? state.call(this, target) : state;
        Object.keys(source).forEach((key)=>{
            if ({}.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        });
        this.update.call(this, this.state, source);
    }
    
    /**
     * placeholder - update function called every time a setState is invoked
     * @param {Obj} newState
     * @param {Obj} oldState
     */
    update() {
        return;
    }

    
    getState() {
        return this.state;
    }
    
    /**
     * placeholder
     */
    get initialState() {
        return {};
	}
	
	/**
     * initialOptions
     */
    get intialOptions() {
        return {
            delegateEvents: true
		};
	}
    
    /**
     * @param selector {String} - DOM selector
     * @param viewClass {String} - viewclass to instantiate
     * 
     */
	
    static viewFactory (selector=null, ViewClass=null) {
        if( selector && ViewClass) {
            const elements = document.querySelectorAll( selector );
            [].forEach.call(elements, (element)=>{
                new ViewClass(element);
            });
        }
    }
}
const Utils = {
    getHeight(el) {
        const elem = el instanceof View ? el.el : el;

        const style = window.getComputedStyle(elem),
            savedProps = {
                display: style.display,
                position: style.position,
                visibility: style.visibility,
                maxHeight: style.maxHeight.replace('px', '').replace('%', '')
            };
        let wantedHeight = 0;


        // if its not hidden we just return normal height
        if(savedProps.display !== 'none' && savedProps.maxHeight !== '0') {
            return elem.offsetHeight;
        }

        // the element is hidden so:
        // making the el block so we can meassure its height but still be hidden
        elem.style.position   = 'absolute';
        elem.style.visibility = 'hidden';
        elem.style.display    = 'block';

        wantedHeight = elem.offsetHeight;

        // reverting to the original values
        elem.style.display    = savedProps.display;
        elem.style.position   = savedProps.position;
        elem.style.visibility = savedProps.visibility;

        return wantedHeight;
    }
}
export { View, EventEmitter, Utils }
    
    
    