import { View, Utils } from '../view'; 

// event polyfill
(function() {
	if (typeof window.CustomEvent === "function") return false;

	function CustomEvent(event, params) {
		params = params || {
			bubbles: false,
			cancelable: false,
			detail: undefined
		};
		var evt = document.createEvent("CustomEvent");
		evt.initCustomEvent(
			event,
			params.bubbles,
			params.cancelable,
			params.detail
		);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.CustomEvent = CustomEvent;
})();

class IframeExample extends View {
	/**
	 *
	 * Adjust iframe behavior when presenting components in iframe-context.
	 * At the moment only able to accomodate one root element in body-tag in the rawtemplate-iframe template.
	 *
	 * TODO:
	 * - Hide iframe before the iframe has run the onload function.
	 * - Insert spinner while loading is performing
	 *
	 */
	constructor(el, settings={}) {
		settings.displayName = "iframeExample";
		super(el, settings);

		this.iframes = this.find(".js--example-iframe");

		[...this.iframes].forEach(iframe => {
			this.setIndividualIframeHeight(iframe);
		});
		this.setIndividualIframeHeight(this.el);
	}

	/**
	 *
	 * Set iframe height to equal the components height
	 *
	 */
	setIndividualIframeHeight(nodeElem) {
		nodeElem.onload = () => {
			var elem = nodeElem.contentDocument.querySelectorAll(
				"body > *:not(script)"
			)[0]; // get around browsersync script-tag that is the first element
			nodeElem.setAttribute("height", `${elem.offsetHeight + 25}px`);
		};
	}
}

export default IframeExample;
