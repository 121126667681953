import { View } from '../view';
import * as ClipboardJS from 'clipboard';

class CopyColor extends View {

    constructor(el, settings) {
		super(el, settings)
		this.clipboard = new ClipboardJS(this.el, {
            text: (trigger) => {
                return trigger.dataset.copyColor
            }
        });
        this.clipboard.on('success', this.onSuccess.bind(this));
        this.clipboard.on('error', this.onError.bind(this));
        this.onMouseOut = this.onMouseOut.bind(this);

        //this.hehe()
    }

    delegateEvents() {
        this.on('mouseleave', this.el, this.onMouseOut);
    }
    
    onSuccess (e) {
        e.clearSelection();
        this.el.setAttribute('aria-label', 'Copied!');
        this.el.classList.add('tooltip-active');
        
        setTimeout(this.onMouseOut, 3000);
    }
    onError (e) {
        console.warn(e);
    }
    onMouseOut (e) {
        this.el.setAttribute('aria-label', '');
        this.el.classList.remove('tooltip-active');
    }
    hehe() {
        let toggle = 1
        const run = () => {
            const e = document.createElement('p');
            e.innerHTML = 'SH <3 ET'
            Object.assign(e.style, { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '23vw', zIndex: '100', textAlign: 'center', width: '100vw' })
            document.body.appendChild(e)
            setInterval(() => {
                document.documentElement.style.filter = `invert(${toggle})`
                toggle = toggle ? 0 : 1
            }, 150)
        }
    }
}

export default CopyColor;