import { View, Utils } from '../view';

class Expandable extends View {

	constructor(el, settings={}) {
		
		settings.displayName = 'Expandable';
		super(el, settings);
		this.active = this.el.classList.contains('is-active');
		
		this.content = this.find('.js--expandable-content')[0];
		this.content.style.maxHeight = 'none';

		this.minHeight = this.content.getAttribute('data-minheight') || '0px';
		if(this.minHeight.indexOf('px') == -1){
			this.minHeight = this.minHeight + 'px';
		}
		
		setTimeout(() => {
			// if(this.hasClass('sh-dev-notes')){ // markdown content needs the accordion__content padding as well
			this.maxHeight = Utils.getHeight(this.content) + 60;
			// } else {
				// this.maxHeight = Utils.getHeight(this.content);
				// }
				if ( !this.active ) this.content.style.maxHeight = this.minHeight;
				
				this.savedWindowWidth = window.innerWidth;
			}, 20);
		}
		
	delegateEvents () {
		this.on('resize', window, this.resizeHandler);
		this.on('click', '.js--expandable-trigger', this.onToggle);
	}

	onToggle(e){
		e.preventDefault();
		e.stopPropagation();

		this.active = !this.active;
		this.content.style.maxHeight = this.active ? this.maxHeight + 'px' : this.minHeight;
		this.el.classList.toggle('is-active', this.active);
	}

	handleInlineButtonClick(e){
		e.stopPropagation();
	}

	resizeHandler() {
		const width = window.outerWidth;
		if ( this.savedWindowWidth === width ) return false;
		else {
			this.savedWindowWidth = width;
			if ( this.active ) {
				this.content.style.maxHeight = 'none';
				this.maxHeight = Utils.getHeight(this.content);
			}
			else {
				this.content.style.maxHeight = 'none';
				this.content.style.opacity = '0';
				setTimeout(()=>{
					this.maxHeight = Utils.getHeight(this.content);
					this.content.style.maxHeight = this.minHeight;
					this.content.style.opacity = '1';
				}, 10);
			}
		}
	}
}

export default Expandable;
