import { Events, View } from '../view';

class Search extends View {

	constructor(el, options={}) {
		super(el, {
			...options,
			displayName: "Search",
			delegateEvents: false
		});

		this.isActive = false;

		this.initiator = document.querySelectorAll('.sh-search-initiator')[0];
		this.input = this.find('input')[0];
		this.inputFocused = false;
		this.closeSearch = this.find('.sh-close-search')[0];
		this.noResultsCon = this.find('.sh-search-results-container.no-results')[0];

		this.searchVersion = 2;

		// binding 
		this.showSearchModal = this.showSearchModal.bind(this);
		this.hideSearchModal = this.hideSearchModal.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.keyboardActivateSearch = this.keyboardActivateSearch.bind(this);
		this.handleEscKeypress = this.handleEscKeypress.bind(this);

		this.delegateEvents();
	}
	
	delegateEvents () {
		this.on('click', this.initiator, this.showSearchModal);
		this.on('click', this.closeSearch, this.hideSearchModal);
		this.on('keyup', this.input, this.doSearch);
	
		this.on('focus', this.input, (e) => {
			this.inputFocused = true;
		});
		this.input.addEventListener('blur', (e) => { // "Utils.on" cannot set dispatch to "true"
			this.inputFocused = false;
		}, true);
		this.on('keydown', document, this.keyboardActivateSearch);
	}

	keyboardActivateSearch(e){
		if(e.which == 83 && this.inputFocused == false){ // press "s" to activate search modal
			this.showSearchModal(e);
		}
	}

	setupModalTransformOrigin(){ // v1 stuff
		var coords = this.initiator.getBoundingClientRect();
		this.el.setAttribute('style', 'transform-origin: ' + Math.round(coords.left) + 'px ' + Math.round(coords.top) + 'px;');
	}

	doV2Stuff(){
		var shMain = document.querySelectorAll('.sh-main-container')[0];
		shMain.classList.add('search-active');

		setTimeout(() => {
			this.el.classList.add('post-active');
		}, 250); // a tiny delay is needed for the animation to work
	}

	undoV2Stuff(){
		const shMain = document.querySelector('.sh-main-container');
		shMain.classList.remove('search-active');
		this.el.classList.remove('post-active');
	}

	showSearchModal(e){
		if(this.searchVersion == 1){
			// this.setupModalTransformOrigin(); // v1 stuff
		} else if(this.searchVersion == 2) {
			this.doV2Stuff();
		}
		this.isActive = true;
		this.el.classList.add('pre-active'); // set animation pre-state (display: block; etc.)
		setTimeout(() => {
			this.el.classList.add('is-active');
			this.on('keyup', document, this.handleEscKeypress);
			this.input.focus();
		}, 50); // a tiny delay is needed for the animation to work
	}

	hideSearchModal(e){
		if(this.searchVersion == 2) {
			this.undoV2Stuff();
		}
		this.input.blur(); // blur event is not fired by itself
		this.isActive = false;
		this.el.classList.remove('is-active');
		setTimeout(() => { this.el.classList.remove('pre-active'); }, 250); // delay(transition duration) to show the animation going back
	}

	handleEscKeypress(e){
		if(e.which == 27){
			if(this.input.value.length){
				this.input.value = '';
			} else {
				this.hideSearchModal();
			}
		}
		this.off('keyup', document, this.handleEscKeypress);
	}

	doSearch(e){
		e.preventDefault();
		e.stopPropagation();

		if(e.which == 27){
			this.handleEscKeypress(e);
			return;
		}

		var ajax,
			timeout,
			searchTerm = this.input.value;

		if (searchTerm.length > 1) {
			if(timeout){
				timeout.clearTimeout();
			}
			if(ajax){
				ajax.abort();
			}
			timeout = setTimeout(() => {
				ajax = fetch('/api/searchendpoint', {
					method: 'POST',
					cache: 'no-cache',
					headers: { // basic auth for design system
						'Content-Type': 'application/json',
						// 'Authorization': 'Basic ' + btoa('username:password')
					},
					body: JSON.stringify({searchTerm: searchTerm})
				}).then((response) => {
					ajax = null;
					return response.json();
				}).then((response) => {
					if(response.status == 'ok'){
						this.formatResults(response);
					} else {
						this.showNoResults();
					}
					ajax = null;
				});
			}, 100);
		}
	}

	showNoResults(){
		this.noResultsCon.classList.add('is-active');
	}

	formatResults(results){
		var output = '',
			resultsFound = 0;

		this.noResultsCon.classList.remove('is-active');

		if(results.searchResults.cms.length){
			output += this.formatResultCategory('Design manual:', results.searchResults.cms);
			resultsFound++;
		}
		if(results.searchResults.examples.length){
			output += this.formatResultCategory('Example pages:', results.searchResults.examples);
			resultsFound++;
		}
		if(results.searchResults.uilib.length){
			output += this.formatResultCategory('UI Library:', results.searchResults.uilib);
			resultsFound++;
		}

		if(resultsFound == 0){
			this.showNoResults();
		} else {
			this.find('.sh-search-results-container')[0].innerHTML = output;
		}
	}

	formatResultCategory(heading, results){
		const result = results.map(result => {
			return this.formatResult(result);
		});
		return `<div class="sh-search-results"><h5 class="sh-search-results-heading">${heading}</h5>${result.join('')}</div>`;
	
	}

	formatResult(result){
		return `<div class="sh-search-result"><a href="${result.url}">${result.name}</a></div>`;
	}
}

export default Search;
